/* Generated by restful-react */

import React from "react";
import { Get, GetProps, useGet, UseGetProps, Mutate, MutateProps, useMutate, UseMutateProps } from "restful-react";
export const SPEC_VERSION = "1.0.0"; 
export type AccessMode = "RW" | "R" | "O";

export interface Error {
  code?: string;
  message?: string;
}

export type ErrorCode = "UNAUTHENTICATED" | "UNAUTHORIZED" | "INTERNAL" | "UNAVAILABLE";

export type List = ListShort & {
  items?: ListItem[];
};

export interface ListItem {
  id?: string;
  text?: string;
}

export interface ListShort {
  accepted?: boolean;
  access?: AccessMode;
  id?: string;
  inviter?: string;
  name?: string;
}

export interface ListUser {
  accepted?: boolean;
  access_mode?: AccessMode;
  me?: boolean;
  user_name?: string;
}

export interface User {
  avatar_id?: string;
  name?: string;
}

/**
 * error
 */
export type ErrorResponse = Error;

export type UserInfoProps = Omit<GetProps<User, ErrorResponse, void, void>, "path">;

export const UserInfo = (props: UserInfoProps) => (
  <Get<User, ErrorResponse, void, void>
    path={`/api/me`}
    
    {...props}
  />
);

export type UseUserInfoProps = Omit<UseGetProps<User, ErrorResponse, void, void>, "path">;

export const useUserInfo = (props: UseUserInfoProps) => useGet<User, ErrorResponse, void, void>(`/api/me`, props);


export type ListListsProps = Omit<GetProps<ListShort[], ErrorResponse, void, void>, "path">;

export const ListLists = (props: ListListsProps) => (
  <Get<ListShort[], ErrorResponse, void, void>
    path={`/api/todo-lists`}
    
    {...props}
  />
);

export type UseListListsProps = Omit<UseGetProps<ListShort[], ErrorResponse, void, void>, "path">;

export const useListLists = (props: UseListListsProps) => useGet<ListShort[], ErrorResponse, void, void>(`/api/todo-lists`, props);


export interface CreateListResponse {
  id: string;
}

export interface CreateListRequestBody {
  name: string;
}

export type CreateListProps = Omit<MutateProps<CreateListResponse, ErrorResponse, void, CreateListRequestBody, void>, "path" | "verb">;

export const CreateList = (props: CreateListProps) => (
  <Mutate<CreateListResponse, ErrorResponse, void, CreateListRequestBody, void>
    verb="POST"
    path={`/api/todo-lists`}
    
    {...props}
  />
);

export type UseCreateListProps = Omit<UseMutateProps<CreateListResponse, ErrorResponse, void, CreateListRequestBody, void>, "path" | "verb">;

export const useCreateList = (props: UseCreateListProps) => useMutate<CreateListResponse, ErrorResponse, void, CreateListRequestBody, void>("POST", `/api/todo-lists`, props);


export interface AcceptInvitationPathParams {
  list_id: string
}

export interface AcceptInvitationRequestBody {
  alias: string;
}

export type AcceptInvitationProps = Omit<MutateProps<void, ErrorResponse, void, AcceptInvitationRequestBody, AcceptInvitationPathParams>, "path" | "verb"> & AcceptInvitationPathParams;

export const AcceptInvitation = ({list_id, ...props}: AcceptInvitationProps) => (
  <Mutate<void, ErrorResponse, void, AcceptInvitationRequestBody, AcceptInvitationPathParams>
    verb="POST"
    path={`/api/todo-lists/${list_id}/accept_invitation`}
    
    {...props}
  />
);

export type UseAcceptInvitationProps = Omit<UseMutateProps<void, ErrorResponse, void, AcceptInvitationRequestBody, AcceptInvitationPathParams>, "path" | "verb"> & AcceptInvitationPathParams;

export const useAcceptInvitation = ({list_id, ...props}: UseAcceptInvitationProps) => useMutate<void, ErrorResponse, void, AcceptInvitationRequestBody, AcceptInvitationPathParams>("POST", (paramsInPath: AcceptInvitationPathParams) => `/api/todo-lists/${paramsInPath.list_id}/accept_invitation`, {  pathParams: { list_id }, ...props });


export interface InviteUserPathParams {
  list_id: string
}

export interface InviteUserRequestBody {
  access_mode: AccessMode;
  invitee: string;
}

export type InviteUserProps = Omit<MutateProps<void, ErrorResponse, void, InviteUserRequestBody, InviteUserPathParams>, "path" | "verb"> & InviteUserPathParams;

export const InviteUser = ({list_id, ...props}: InviteUserProps) => (
  <Mutate<void, ErrorResponse, void, InviteUserRequestBody, InviteUserPathParams>
    verb="POST"
    path={`/api/todo-lists/${list_id}/inviteUser`}
    
    {...props}
  />
);

export type UseInviteUserProps = Omit<UseMutateProps<void, ErrorResponse, void, InviteUserRequestBody, InviteUserPathParams>, "path" | "verb"> & InviteUserPathParams;

export const useInviteUser = ({list_id, ...props}: UseInviteUserProps) => useMutate<void, ErrorResponse, void, InviteUserRequestBody, InviteUserPathParams>("POST", (paramsInPath: InviteUserPathParams) => `/api/todo-lists/${paramsInPath.list_id}/inviteUser`, {  pathParams: { list_id }, ...props });


export interface AddItemPathParams {
  list_id: string
}

export interface AddItemRequestBody {
  text: string;
}

export type AddItemProps = Omit<MutateProps<void, ErrorResponse, void, AddItemRequestBody, AddItemPathParams>, "path" | "verb"> & AddItemPathParams;

export const AddItem = ({list_id, ...props}: AddItemProps) => (
  <Mutate<void, ErrorResponse, void, AddItemRequestBody, AddItemPathParams>
    verb="POST"
    path={`/api/todo-lists/${list_id}/items`}
    
    {...props}
  />
);

export type UseAddItemProps = Omit<UseMutateProps<void, ErrorResponse, void, AddItemRequestBody, AddItemPathParams>, "path" | "verb"> & AddItemPathParams;

export const useAddItem = ({list_id, ...props}: UseAddItemProps) => useMutate<void, ErrorResponse, void, AddItemRequestBody, AddItemPathParams>("POST", (paramsInPath: AddItemPathParams) => `/api/todo-lists/${paramsInPath.list_id}/items`, {  pathParams: { list_id }, ...props });


export interface DeleteItemPathParams {
  list_id: string;
  item_id: string
}

export type DeleteItemProps = Omit<MutateProps<void, ErrorResponse, void, void, DeleteItemPathParams>, "path" | "verb"> & DeleteItemPathParams;

export const DeleteItem = ({list_id, item_id, ...props}: DeleteItemProps) => (
  <Mutate<void, ErrorResponse, void, void, DeleteItemPathParams>
    verb="DELETE"
    path={`/api/todo-lists/${list_id}/items/${item_id}/wa`}
    
    {...props}
  />
);

export type UseDeleteItemProps = Omit<UseMutateProps<void, ErrorResponse, void, void, DeleteItemPathParams>, "path" | "verb"> & DeleteItemPathParams;

export const useDeleteItem = ({list_id, item_id, ...props}: UseDeleteItemProps) => useMutate<void, ErrorResponse, void, void, DeleteItemPathParams>("DELETE", (paramsInPath: DeleteItemPathParams) => `/api/todo-lists/${paramsInPath.list_id}/items/${paramsInPath.item_id}/wa`, {  pathParams: { list_id, item_id }, ...props });


export interface GetListUsersPathParams {
  list_id: string
}

export type GetListUsersProps = Omit<GetProps<ListUser[], ErrorResponse, void, GetListUsersPathParams>, "path"> & GetListUsersPathParams;

export const GetListUsers = ({list_id, ...props}: GetListUsersProps) => (
  <Get<ListUser[], ErrorResponse, void, GetListUsersPathParams>
    path={`/api/todo-lists/${list_id}/users`}
    
    {...props}
  />
);

export type UseGetListUsersProps = Omit<UseGetProps<ListUser[], ErrorResponse, void, GetListUsersPathParams>, "path"> & GetListUsersPathParams;

export const useGetListUsers = ({list_id, ...props}: UseGetListUsersProps) => useGet<ListUser[], ErrorResponse, void, GetListUsersPathParams>((paramsInPath: GetListUsersPathParams) => `/api/todo-lists/${paramsInPath.list_id}/users`, {  pathParams: { list_id }, ...props });


export interface RevokeInvitationPathParams {
  list_id: string;
  user_id: string
}

export type RevokeInvitationProps = Omit<MutateProps<void, ErrorResponse, void, void, RevokeInvitationPathParams>, "path" | "verb"> & RevokeInvitationPathParams;

export const RevokeInvitation = ({list_id, user_id, ...props}: RevokeInvitationProps) => (
  <Mutate<void, ErrorResponse, void, void, RevokeInvitationPathParams>
    verb="DELETE"
    path={`/api/todo-lists/${list_id}/users/${user_id}/wa`}
    
    {...props}
  />
);

export type UseRevokeInvitationProps = Omit<UseMutateProps<void, ErrorResponse, void, void, RevokeInvitationPathParams>, "path" | "verb"> & RevokeInvitationPathParams;

export const useRevokeInvitation = ({list_id, user_id, ...props}: UseRevokeInvitationProps) => useMutate<void, ErrorResponse, void, void, RevokeInvitationPathParams>("DELETE", (paramsInPath: RevokeInvitationPathParams) => `/api/todo-lists/${paramsInPath.list_id}/users/${paramsInPath.user_id}/wa`, {  pathParams: { list_id, user_id }, ...props });


export interface DeleteListPathParams {
  list_id: string
}

export type DeleteListProps = Omit<MutateProps<void, ErrorResponse, void, void, DeleteListPathParams>, "path" | "verb"> & DeleteListPathParams;

export const DeleteList = ({list_id, ...props}: DeleteListProps) => (
  <Mutate<void, ErrorResponse, void, void, DeleteListPathParams>
    verb="DELETE"
    path={`/api/todo-lists/${list_id}/wa`}
    
    {...props}
  />
);

export type UseDeleteListProps = Omit<UseMutateProps<void, ErrorResponse, void, void, DeleteListPathParams>, "path" | "verb"> & DeleteListPathParams;

export const useDeleteList = ({list_id, ...props}: UseDeleteListProps) => useMutate<void, ErrorResponse, void, void, DeleteListPathParams>("DELETE", (paramsInPath: DeleteListPathParams) => `/api/todo-lists/${paramsInPath.list_id}/wa`, {  pathParams: { list_id }, ...props });


export interface GetListPathParams {
  list_id: string
}

export type GetListProps = Omit<GetProps<List, ErrorResponse, void, GetListPathParams>, "path"> & GetListPathParams;

export const GetList = ({list_id, ...props}: GetListProps) => (
  <Get<List, ErrorResponse, void, GetListPathParams>
    path={`/api/todo-lists/${list_id}/wa`}
    
    {...props}
  />
);

export type UseGetListProps = Omit<UseGetProps<List, ErrorResponse, void, GetListPathParams>, "path"> & GetListPathParams;

export const useGetList = ({list_id, ...props}: UseGetListProps) => useGet<List, ErrorResponse, void, GetListPathParams>((paramsInPath: GetListPathParams) => `/api/todo-lists/${paramsInPath.list_id}/wa`, {  pathParams: { list_id }, ...props });


export type PageLoginProps = Omit<GetProps<void, unknown, void, void>, "path">;

export const PageLogin = (props: PageLoginProps) => (
  <Get<void, unknown, void, void>
    path={`/login`}
    
    {...props}
  />
);

export type UsePageLoginProps = Omit<UseGetProps<void, unknown, void, void>, "path">;

export const usePageLogin = (props: UsePageLoginProps) => useGet<void, unknown, void, void>(`/login`, props);


export interface PageReceiveTokenQueryParams {
  state?: string;
  code: string;
}

export type PageReceiveTokenProps = Omit<GetProps<void, unknown, PageReceiveTokenQueryParams, void>, "path">;

export const PageReceiveToken = (props: PageReceiveTokenProps) => (
  <Get<void, unknown, PageReceiveTokenQueryParams, void>
    path={`/receive-token`}
    
    {...props}
  />
);

export type UsePageReceiveTokenProps = Omit<UseGetProps<void, unknown, PageReceiveTokenQueryParams, void>, "path">;

export const usePageReceiveToken = (props: UsePageReceiveTokenProps) => useGet<void, unknown, PageReceiveTokenQueryParams, void>(`/receive-token`, props);

